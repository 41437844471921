* {
  padding: 0;
  margin: 0;
}
html {
  background: #041014;
}
p {
  color: white;
}
button {
  margin-top: 0.5rem;
}
h1 {
  color: white;
}
select {
  display: block;
  width: 100%;
}
button {
  width: 100%;
}
.wrapper {
  text-align: center;
  margin-top: 5rem;
}

.grid-container {
  display: grid;
  box-sizing: content-box;
  justify-content: center;
  margin-top: 2rem;
  border: 0.5px solid lightgray;
  height: 20rem;
  width: 20rem;
}
.options-wrapper {
  margin-top: auto;
  margin-left: 1rem;
}

.main-container {
  display: flex;
  justify-content: center;
}
